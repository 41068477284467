<template>
  <div>
    <edit-block
      :showStatus="true"
      :AuditStatus="dataSource.AuditStatus"
      :state="state"
      :title="title"
    >
      <!-- 未填写按钮 -->
      <div slot="opt">
        <span @click="updateState(1)" class="text-primary cursor-pointer"
          >添加</span
        >
      </div>
      <!-- 填写中 -->
      <ProductEdit
        @cancel="cancel"
        @save="finish"
        :model="model"
        :status="status"
        slot="editing"
        :options="options"
      />
      <!-- 已填写 -->
      <ProductDisplay :options="options" :model="model" slot="display" />
      <!-- 已填写按钮 -->
      <div slot="display-opt" class="flex items-center" v-if="role !== 8">
        <div class="display-opt">
          <a-space :size="10">
            <a-popconfirm
              title="是否删除该产品?"
              ok-text="删除"
              cancel-text="取消"
              @confirm="del"
            >
              <a-space class="cursor-pointer text-primary" v-if="!isManager"
                ><img src="@/assets/icons/del.svg" alt="" /><span
                  >删除</span
                ></a-space
              >
            </a-popconfirm>

            <a-space
              class="cursor-pointer text-primary"
              @click="submit"
              v-if="!isManager"
            >
              <img src="@/assets/icons/commit.svg" alt="" />
              <span>提交</span>
            </a-space>
            <a-space
              @click="updateState(1)"
              class="cursor-pointer text-primary"
            >
              <img src="@/assets/icons/modify.svg" alt="" />
              <span>修改</span>
            </a-space>
          </a-space>
        </div>
        <a-select
          v-if="!isManager"
          v-model="visible"
          @change="changeVisible"
          style="width: 150px; margin-left: 10px"
        >
          <a-select-option :value="2"> 全公开 </a-select-option>
          <a-select-option :value="1"> 向星球伙伴公开 </a-select-option>
          <a-select-option :value="0"> 不公开 </a-select-option>
        </a-select>
      </div>
    </edit-block>
    <div class="text-right" v-if="isManager && AuditStatus === 1">
      <a-button type="primary mt-20" @click="modal = true">审核</a-button>
    </div>
    <a-modal
      v-model="modal"
      title="审核"
      okText="审核"
      cancelText="取消"
      @ok="evaluteProProduct"
    >
      <a-form-model :model="evaluate">
        <a-form-model-item>
          <a-select v-model="evaluate.AuditStatus">
            <a-select-option :value="2">审核通过</a-select-option>
            <a-select-option :value="3">审核失败</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item v-if="evaluate.AuditStatus === 3">
          <gf-textarea
            :required="true"
            prop="Feedback"
            :max="300"
            :rows="5"
            v-model="evaluate.Feedback"
          ></gf-textarea>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import ProductEdit from "@/components/user/product/editing.vue";
import ProductDisplay from "@/components/user/product/display.vue";

import { setProductVisible } from "@/api/product.js";

import {
  saveProduct,
  deleteProduct,
  evaluateProduct,
  createProduct,
} from "@/api";

import { evaluteProProduct, saveProProduct } from "@/api/product.js";

export default {
  components: { ProductDisplay, ProductEdit },
  props: {
    dataSource: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      model: {},
      rules: {},
      state: 0,
      title: "",
      id: 0,
      AuditStatus: 0,
      status: true,
      evaluate: {
        AuditStatus: 2,
        Feedback: "",
      },
      modal: false,
      visible: 0,
    };
  },
  computed: {
    role() {
      return this.$store.state.role;
    },
    isManager() {
      return this.role === 6 || this.role === 7;
    },
  },
  inject: ["options"],
  methods: {
    transform({
      Name,
      Tags,
      Attachments,
      KeyWords,
      Desc,
      Industries,
      Detail,
      Photos,
      Industrial,
    }) {
      const temp = {
        Name,
        Desc,
      };

      temp.Photos = Photos ? Photos[0] : null;
      temp.Industries = Industries || [];
      temp.KeyWords = KeyWords || [];
      temp.KeyWords1 = temp.KeyWords[0];
      temp.KeyWords2 = temp.KeyWords[1];
      temp.Tags = Tags || [{}];
      temp.Attachments = Attachments || [];
      temp.Detail = Detail || [];
      temp.Industrial = Industrial;
      this.updateState(this.getState(temp), temp.Name);
      this.validate(temp);
      return temp;
    },
    getState({
      Name,
      Photos,
      Detail,
      Attachments,
      Tags,
      KeyWords,
      Desc,
      Industries,
    }) {
      if (
        this.id &&
        (Photos ||
          Name ||
          Detail.length ||
          Attachments.length ||
          Tags.length ||
          KeyWords.length ||
          Desc ||
          Industries.length)
      ) {
        return 2;
      }
      return 0;
    },
    restore({
      ID,
      Photos,
      Detail,
      Attachments,
      Name,
      Tags,
      KeyWords1,
      KeyWords2,
      Desc,
      IndustryIDs,
      Industry,
    }) {
      const temp = {
        Detail,
        Attachments,
        ID,
        Name,
        Desc,
      };
      temp.KeyWords = [KeyWords1, KeyWords2];
      temp.Industries = [];
      IndustryIDs.forEach((item) => {
        if (item > 0) {
          temp.Industries.push({
            ID: item,
            Name: this.options.industry[item - 1].Name,
          });
        }
      });
      if (IndustryIDs.includes(-1)) {
        Industry.forEach((item) => {
          temp.Industries.push({
            ID: 0,
            Name: item,
          });
        });
      }

      temp.Tags = [];
      Tags.forEach((item) => {
        temp.Tags.push({
          ID: item.ID,
          Name: this.options.tag.find((tag) => tag.ID === item.ID).Name,
          Desc: item.Desc,
        });
      });

      temp.Photos = [Photos];

      return temp;
    },
    validate({ Photos, Name, Tags, KeyWords, Desc, Industries }) {
      let status = true;
      if (
        !Photos ||
        !Name ||
        !Tags.length ||
        !KeyWords.length ||
        !Desc ||
        !Industries.length
      ) {
        status = false;
      }
      this.status = status;
    },
    cancel(name) {
      this.updateState(this.getState(this.model), name);
    },
    finish(data) {
      const temp = this.restore(data);
      temp.ID = this.id;
      if (temp.ID) {
        if (this.isManager) {
          saveProProduct(temp.ID, temp).then(() => {
            this.$message.success("保存产品成功");
            this.model = this.transform(data);
            this.$emit("update");
          });
        } else {
          saveProduct(temp).then(() => {
            this.$message.success("保存产品成功");
            this.model = this.transform(data);
            this.$emit("update");
          });
        }
      } else {
        createProduct(temp).then(() => {
          this.$message.success("添加新产品");
          this.model = this.transform(data);
          this.$emit("update");
        });
      }
    },
    updateState(state, name) {
      switch (state) {
        case 0:
          this.title = "新增核心产品";
          break;
        case 1:
          if (this.state === 0) {
            this.title = "添加核心产品";
          } else if (this.state === 2) {
            this.title = "修改核心产品";
          }
          break;
        case 2:
          this.title = name;
          break;
      }
      this.state = state;
    },
    submit() {
      evaluateProduct(this.dataSource.ID).then(() => {
        this.$message.success("提交审核成功");
        this.$emit("update");
      });
    },
    evaluteProProduct() {
      evaluteProProduct(this.id, this.evaluate).then(() => {
        this.$message.success("审核成功");
        this.$emit("update");
        this.modal = false;
      });
    },
    del() {
      deleteProduct(this.dataSource.ID).then(() => {
        this.$message.success("删除成功");
        this.$emit("update");
      });
    },
    changeVisible(e) {
      let temp = {};
      if (e === 2) {
        temp = {
          Public: true,
          Visible: true,
        };
      } else if (e === 1) {
        temp = {
          Public: false,
          Visible: true,
        };
      } else {
        temp = {
          Public: false,
          Visible: false,
        };
      }
      setProductVisible(this.id, temp).then(() => {
        this.$message.success("设置可见性成功");
      });
    },
  },
  watch: {
    dataSource: {
      handler(val) {
        const temp = val && val.Detail ? val.Detail : {};
        this.id = val.ID;
        this.visible = +val.Public + +val.Visible;
        this.AuditStatus = val.AuditStatus;
        this.model = this.transform({ ...temp, Industrial: val.Industrial });
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>